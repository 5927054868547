<template>
  <div>
    <div
      v-if="!checkIfPermission('view calculator', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1>Transaction Calculator</h1>
      <v-dialog
        @click:outside="handleOutsideClick"
        width="850"
        v-model="showTransactionSummary"
      >
        <v-card class="pa-5">
          <h3 class="mb-5">Transaction Summary</h3>
          <template>
            <div>
              <p v-if="dates">
                Total Transactions from
                <b>{{ new Date(dates[0]).toDateString() }}</b> to
                <b>{{ new Date(dates[1]).toDateString() }}</b
                >: {{ apiData.total }}
              </p>
              <div
                v-if="
                  transaction.productType &&
                  transaction.productType.toLowerCase() !== 'giftcard'
                "
              >
                <div>
                  Total Dollar Amount (Nigeria):
                  <b
                    >$
                    {{
                      numberWithCommas(
                        totalMoney.Nigeria ? totalMoney.Nigeria.usd_total : ''
                      )
                    }}</b
                  >
                </div>
                <div>
                  Total Dollar Amount (Ghana):
                  <b
                    >$
                    {{
                      numberWithCommas(
                        totalMoney.Ghana ? totalMoney.Ghana.usd_total : ''
                      )
                    }}</b
                  >
                </div>
                <div>
                  Total Dollar Amount (Kenya):
                  <b
                    >$
                    {{
                      numberWithCommas(
                        totalMoney.Kenya ? totalMoney.Kenya.usd_total : ''
                      )
                    }}</b
                  >
                </div>
              </div>

              <p>
                Total Naira Amount:
                <b
                  >{{ getCurrencySymbol('nigeria') }}
                  {{
                    numberWithCommas(
                      totalMoney.Nigeria ? totalMoney.Nigeria.total : 0
                    )
                  }}</b
                >
              </p>
              <p>
                Total Cedi Amount:
                <b>
                  {{ getCurrencySymbol('ghana') }}
                  {{
                    numberWithCommas(
                      totalMoney.Ghana ? totalMoney.Ghana.total : 0
                    )
                  }}</b
                >
              </p>
              <p>
                Total Shilling Amount:
                <b>
                  {{ getCurrencySymbol('kenya') }}
                  {{
                    numberWithCommas(
                      totalMoney.Kenya ? totalMoney.Kenya.total : 0
                    )
                  }}</b
                >
              </p>
              <div v-if="gottenTransaction.length === 0 && !loadingTransaction">
                <p>No transaction between the selected dates</p>
              </div>
              <div v-else>
                <v-data-table
                  v-if="
                    transaction.productType &&
                    transaction.productType.toLowerCase() === 'giftcard'
                  "
                  :headers="headersGiftCard"
                  :items="arrangedTransactionGiftCard"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50]
                  }"
                  @update:items-per-page="updateItemPerPage"
                  @update:page="updatePage"
                  :server-items-length="apiData.total"
                  :loading="loadingTransaction"
                  :items-per-page="perPage"
                >
                  <template v-slot:[`item.seller`]="{ item }">
                    <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.seller_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.seller }}</router-link
              > -->
                    <span
                      class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                    >
                      {{ item.seller }}
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:[`item.giftcard`]="{ item }">
                    <v-img
                      width="45"
                      class="ma-1"
                      :src="item.giftcard ? item.giftcard.image : ''"
                    />
                    <span class="font-weight-medium">{{
                      item.giftcard ? item.giftcard.name : ''
                    }}</span>
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    <small class="font-weight-medium">{{ item.date }}</small>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <span
                      >{{ item.price }}
                      <small class="grey--text text--darken-2">
                        {{ item.currencyCode }}
                      </small></span
                    >
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>
                      <span class="grey--text text--darken-2">$</span>
                      {{ item.amount }}</span
                    >
                  </template>
                </v-data-table>
                <v-data-table
                  v-else
                  :headers="headersCoin"
                  :items="arrangedTransaction"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50]
                  }"
                  @update:items-per-page="updateItemPerPage"
                  @update:page="updatePage"
                  :server-items-length="apiData.total"
                  :loading="loadingTransaction"
                  :items-per-page="perPage"
                >
                  <template v-slot:[`item.buyer`]="{ item }">
                    <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.buyer_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.buyer }}</router-link
              > -->
                    <span
                      class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                    >
                      {{ item.buyer }}
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:[`item.currency`]="{ item }">
                    <span class="font-weight-medium">{{ item.currency }}</span>
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    <small class="font-weight-medium">{{ item.date }}</small>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <span
                      >{{ item.price }}
                      <small class="grey--text text--darken-2">
                        {{ item.currencyCode }}
                      </small></span
                    >
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>
                      <span class="grey--text text--darken-2">$</span>
                      {{ item.amount }}</span
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
          </template>
        </v-card>
      </v-dialog>
      <div class="mt-10 px-10">
        <v-row>
          <v-col>
            <v-card class="pa-4 px-6" outlined color="white">
              <v-select
                v-model="transaction.productType"
                :items="['Sale', 'Purchase', 'Giftcard']"
                label="Product Type"
              ></v-select>
              <v-select
                v-model="transaction.transactionType"
                :items="['Approved', 'Pending', 'Declined']"
                label="Transaction Type"
              ></v-select>
              <!-- <v-select :items="['Naira', 'Dollar']" label="Currency"></v-select> -->
              <v-text-field
                label="Start From"
                v-model="dates[0]"
                type="date"
              ></v-text-field>

              <v-text-field
                label="End At"
                v-model="dates[1]"
                type="date"
              ></v-text-field>

              <div>
                Start From: {{ dates[0] }} <br />
                End At: {{ dates[1] }}
              </div>
              <div class="mt-4">
                <v-btn
                  block
                  color="primary"
                  @click="getAllTransactions"
                  :loading="gettingTransaction"
                >
                  Calculate
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import transactionApi from '../../api/admin/transactions'
import { getCurrencySymbol } from '../../utils/countryHelpers'
import { numberWithCommas } from '../../utils/helpers'

export default {
  data: () => ({
    dates: [],
    getCurrencySymbol,
    numberWithCommas,
    gettingTransaction: false,
    showTransactionSummary: false,
    gottenTransaction: [],
    transaction: {
      productType: '',
      transactionType: '',
      currency: ''
    },
    headersCoin: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Buyer',
        sortable: false,
        value: 'buyer'
      },
      {
        text: 'Currency',
        value: 'currency',
        sortable: false
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Price (USD)',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headersGiftCard: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Seller',
        sortable: false,
        value: 'seller'
      },
      {
        text: 'Giftcard',
        value: 'giftcard',
        sortable: false
      },
      {
        text: 'Price',
        value: 'price'
      },

      {
        text: 'Status',
        value: 'status'
      }
    ],
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1,
    totalMoney: {}
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    dateRangeText() {
      return this.dates.join(' => ')
    },
    arrangedTransactionGiftCard() {
      if (this.gottenTransaction.length !== []) {
        return this.gottenTransaction.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          date: transaction.date_string,
          seller: transaction?.user.username,
          seller_id: transaction?.user._id,
          status: transaction.status,
          giftcard: transaction.giftcard,
          price: numberWithCommas(transaction.main_amo)
        }))
      }
      return []
    },
    arrangedTransaction() {
      if (this.gottenTransaction.length !== []) {
        return this.gottenTransaction.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          date: transaction.date_string,
          buyer: transaction?.user.username,
          buyer_id: transaction?.user._id,
          currency: transaction.coin
            ? transaction?.coin.name
            : transaction?.currency
            ? transaction?.currency?.name
            : '',
          status: transaction.status,
          price: numberWithCommas(transaction.main_amo),
          amount: numberWithCommas(transaction.amount)
        }))
      }
      return []
    }
  },
  methods: {
    handleChange(value, type) {
      this.transaction = {
        productType: '',
        transactionType: '',
        [type]: value
      }
    },
    handleOutsideClick() {
      this.transaction = {
        productType: '',
        transactionType: '',
        currency: ''
      }
      this.dates = []
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    getAllTransactions() {
      this.getCalculatedTransactions()
      this.getTransactions(0)
    },
    async getCalculatedTransactions() {
      this.gettingTransaction = true
      const transactionType =
        this.transaction.productType.toLowerCase() === 'sale' ||
        this.transaction.productType.toLowerCase() === 'purchase'
          ? 'coin'
          : 'giftcard'
      const res = await transactionApi.transactions().calculate({
        type:
          transactionType === 'giftcard'
            ? 'sale'
            : this.transaction.productType.toLowerCase(),
        category: transactionType,
        status: this.transaction.transactionType.toLowerCase(),
        from: this.dates[0],
        to: this.dates[1]
      })
      if (res.error) {
        this.gettingTransaction = false
        return
      }

      const obj = {}

      res.data.data.forEach((item) => {
        if (item.country) {
          obj[item.country] = item
        }
      })

      this.totalMoney = obj
      this.showTransactionSummary = true
      this.gettingTransaction = false
    },
    async getTransactions(length = 0) {
      this.loadingTransaction = true
      const transactionType =
        this.transaction.productType.toLowerCase() === 'sale' ||
        this.transaction.productType.toLowerCase() === 'purchase'
          ? 'coin'
          : 'giftcard'
      const res = await transactionApi.transactions().getAll({
        type:
          transactionType === 'giftcard'
            ? 'sale'
            : this.transaction.productType.toLowerCase(),
        category: transactionType,
        status: this.transaction.transactionType.toLowerCase(),
        limit: this.perPage,
        offset: length,
        populate:
          transactionType === 'coin'
            ? ['user', 'coin', 'currency']
            : ['user', 'currency', 'giftcard'],
        params: {
          from: this.dates[0],
          to: this.dates[1]
        }
      })

      if (res.error) {
        this.gettingTransaction = false
        return
      }

      this.gottenTransaction = res.data.data.data
      this.apiData = res.data.data.meta
      this.showTransactionSummary = true
      this.loadingTransaction = false
    }
  }
}
</script>
