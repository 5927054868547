<template>
  <div class="pa-0" fluid style="height: 100vh">
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto relative">
        <notification-message
          :message.sync="message"
          :type="error && message ? 'error' : 'success'"
        />
        <v-container class="d-flex align-center justify-center h-100">
          <v-card
            width="340"
            flat
            class="text-center animate__animated animate__fadeInUp"
          >
            <v-col>
              <v-img
                :src="logoSnappy"
                width="45"
                height="45"
                class="mb-4 mx-auto text-center d-block"
              />
              <h3>Welcome Admin!</h3>
              <h5 class="font-weight-regular">Sign in into your account</h5>
            </v-col>
            <v-col>
              <v-form ref="loginForm" @submit.prevent="validate">
                <v-container class="h-100">
                  <v-text-field
                    :disabled="loading"
                    name="email"
                    type="email"
                    filled
                    prepend-inner-icon="mdi-email"
                    label="Email"
                    v-model="form.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                  <v-text-field
                    :disabled="loading"
                    :rules="[rules.required]"
                    filled
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    @click:append="showPassword = !showPassword"
                    v-model="form.password"
                  ></v-text-field>
                  <v-btn
                    :loading="loading"
                    class="mt-2"
                    block
                    color="primary"
                    elevation="2"
                    type="submit"
                    >Sign in</v-btn
                  >
                  <!-- <div class="d-flex justify-end mt-5">
                    <router-link
                      class="grey--text text--darken-2 text-decoration-none text-subtitle-2"
                      :to="`/admin/reset-password${
                        form.email !== '' ? `?email=${form.email}` : ''
                      }`"
                      >Reset Password</router-link
                    >
                  </div> -->
                </v-container>
              </v-form>
            </v-col>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from '../../utils/rules'
import logoSnappy from '../../assets/logo.png'
import api from '../../api/admin/auth'
import dataApi from '../../api/systemData'
import permissionApi from '../../api/admin/permissions'
import notificationMessage from '../../components/notification/notificationMessage.vue'

export default {
  components: { notificationMessage },
  data() {
    return {
      message: null,
      error: false,
      logoSnappy,
      loading: false,
      showPassword: false,
      form: { email: '', password: '' },
      redirect: null,
      rules: {
        required: rules.required,
        email: rules.email
      }
    }
  },
  created() {
    this.$store.commit('setTokenExpiredState', false)
    this.redirect = this.$route.query.redirect
  },
  methods: {
    async getCountrySetting() {
      const res = await dataApi.data().countrySetting()
      this.$store.commit('admin/setCountrySetting', res.data.data)
      this.countryValues = this.countrySetting[
        this.selectedCountry.toLocaleLowerCase()
      ]
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.login()
      }
    },

    async login() {
      this.loading = true
      const res = await api
        .auth()
        .login({ email: this.form.email, password: this.form.password })

      if (res.error) {
        // this.message = res.errorMessage.message
        // this.error = true
        this.loading = false
        this.$swal.fire({
          icon: 'error',
          title: 'Login error',
          text:
            res.errorMessage.message ||
            'Unable to login please check your internet connection'
        })
        return
      }

      this.message = `Login Successful`
      this.error = false

      const admin = res.data.data.admin

      this.$store.commit('admin/updateAdminData', admin)

      if (!res.data.data.token) {
        // redirect to verify code
        setTimeout(() => {
          if (this.redirect) {
            this.$router.push(
              `/admin/verify-login/${admin._id}?redirect=${this.redirect}&username=${admin.username}`
            )
            return
          }
          this.$router.push(
            `/admin/verify-login/${admin._id}?username=${admin.username}`
          )
        }, 1500)
      } else {
        const token = res.data.data.token
        this.$store.commit('admin/setToken', token)

        try {
          this.getCountrySetting()
          const profileReq = await permissionApi
            .permissions()
            .getCurrentUserPermissions()
          this.$store.commit(
            'admin/updateAdminPermissions',
            profileReq.data.data
          )
        } catch (err) {}

        setTimeout(() => {
          if (this.redirect) {
            this.$router.push(this.redirect)
            return
          }
          this.$router.push('/admin/admin')
        }, 1000)
      }

      this.loading = false
    }
  }
}
</script>

<style></style>
