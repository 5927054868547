<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    max-width="700px"
  >
    <v-card class="pa-4">
      <v-card-title>Staff Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Registration Date</p>
            <span>{{ staff.registered }}</span>
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Staff Status</p>
            <span
              :class="`${
                staff.status === 'Active' ? 'success--text' : 'error--text'
              } font-weight-bold text-capitalize mt-2 `"
              >{{ staff.status }}</span
            >
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Require 2FA on Login</p>
            <span>
              <v-switch
                v-model="staff.otp"
                color="primary"
                :label="staff.otp ? 'YES' : 'NO'"
                class="ml-2"
                @change="updateStaff"
              ></v-switch>
            </span>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">Wallet Funds</p>
        <div v-for="wallet in staff.walletFundBalances" :key="wallet.currency">
          <v-text-field
            :placeholder="`Funds for ${wallet.currency}`"
            :label="`Funds for ${wallet.currency}`"
            :hint="`Funds for ${wallet.currency}`"
            :value="wallet.balance"
            v-model="wallet.balance"
          ></v-text-field>
        </div>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">staff Info</p>
        <v-row v-for="info in staff.info" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <!-- <p class="overline grey--text">Login information</p> -->
        <v-row v-for="info in staff.moreInfo" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
        <!-- <v-divider class="my-2"></v-divider> -->

        <p>STAFF MANAGEMENT PERMISSION</p>
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="permission in permissions"
            :key="permission._id"
          >
            <v-switch
              :disabled="loading || readonly"
              v-model="staff.permissions"
              :label="permission.name"
              :messages="permission.description"
              :value="permission._id"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions>
        <v-spacer> </v-spacer>

        <v-btn text color="accent" @click="$emit('update:open', false)"
          >Close</v-btn
        >
        <v-btn
          v-if="checkIfPermission('manage staff', admin.permissions)"
          color="accent"
          :loading="loading"
          @click="updateAdmin"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import adminApi from '../../api/admin/admin'
import permissionApi from '../../api/admin/permissions'

export default {
  name: 'UserDetails',
  props: {
    open: Boolean,
    progress: String,
    staff: Object
  },
  data: () => ({
    loading: false,
    permissionsModel: [],
    permissions: []
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    readonly() {
      return !this.checkIfPermission('manage staff', this.admin.permissions)
    }
  },
  created() {
    this.getAvailablePermissions()
  },
  methods: {
    async getAvailablePermissions() {
      const res = await permissionApi.permissions().getAll({
        params: {
          limit: 0,
          offset: 0
        }
      })
      this.permissions = res.data.data.data
      this.permissions.sort((a, b) => {
        const newA = a.name.split(' ')[1]
        const newB = b.name.split(' ')[1]
        if (newA < newB) {
          return -1
        }
        if (newA > newB) {
          return 1
        }
        return 0
      })
    },
    async updatePermission() {
      const res = await permissionApi.permissions().updateUserPermission({
        userId: this.staff._id,
        permissions: this.staff.permissions
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      this.loading = false
    },
    async updateAdmin() {
      this.loading = true
      this.staff.status = true

      if (this.staff._id !== this.admin._id) {
        await this.updatePermission()
      }

      const res = await adminApi
        .users()
        .updateAdminDetails({ data: this.staff, adminId: this.staff._id })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Successful',
        status: true
      })
      this.$emit('update:progress', 'done')
      this.$emit('update:open', false)
      this.loading = false
    },
    async updateStaff() {
      this.loading = true
      console.log(this.staff)
      const res = await adminApi
        .users()
        .toggleOTP({ adminId: this.staff._id, otp: this.staff.otp })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Successful',
        status: true
      })
      this.$emit('update:progress', 'done')
      this.$emit('update:open', false)
      this.loading = false
    }
  }
}
</script>

<style></style>
