var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-10"},[_c('user-details',{attrs:{"open":_vm.userDetailsDialog,"user":_vm.user},on:{"update:open":function($event){_vm.userDetailsDialog=$event}}}),_c('suspend-dialog',{attrs:{"open":_vm.actionOnDialog,"type":_vm.actionType,"id":_vm.actionUserId,"callback":_vm.getUsers},on:{"update:open":function($event){_vm.actionOnDialog=$event}}}),_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-title',[_c('div',{},[_c('h4',[_vm._v("Filter")]),_c('div',[_c('v-select',{attrs:{"items":['All', 'Nigeria', 'Ghana', 'Kenya'],"label":"Country"},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1)]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loadingUser,"items":_vm.users,"search":_vm.search,"headers":_vm.headers,"footer-props":{
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
      },"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-capitalize d-block",attrs:{"to":{ name: 'ViewUser', params: { id: item._id } }}},[_vm._v(_vm._s(((item.fname) + " " + (item.lname))))]),_c('small',{staticClass:"font-weight-regular grey--text text--darken-2 d-block"},[_vm._v(_vm._s(item.username))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:("text-capitalize rounded py-2 px-3 white--text " + (_vm.statusInfo(item.status, item.activeStatus).color))},[_vm._v(_vm._s(_vm.statusInfo(item.status, item.activeStatus).text))])]}},{key:"item.registered",fn:function(ref){
          var item = ref.item;
return [_c('small',[_vm._v(_vm._s(new Date(item.createdAt).toDateString()))])]}},{key:"item.balance",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.balance)),_c('small',{staticClass:"grey--text text--darken-2"},[_vm._v(" NGN")])])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.selectUser(item)}}},[_c('v-list-item-title',[_vm._v("View Details")])],1),(
                item.activeStatus === 'deactivated' &&
                _vm.checkIfPermission('manage user', _vm.admin.permissions)
              )?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.actionOnUser(item, 'activate')}}},[_c('v-list-item-title',[_vm._v("Activate")])],1):_vm._e(),(
                item.activeStatus === 'active' &&
                _vm.checkIfPermission('manage user', _vm.admin.permissions)
              )?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.actionOnUser(item, 'suspend')}}},[_c('v-list-item-title',[_vm._v("Suspend")])],1):_vm._e()],1)],1)]}},{key:"item.verification",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{class:("mx-1 font-weight-medium d-block " + (item.email_verify ? 'success--text' : 'error--text'))},[_vm._v("Email")]),_c('span',{class:("mx-1 font-weight-medium d-block " + (item.kycverify ? 'success--text' : 'error--text'))},[_vm._v("KYC")]),_c('span',{class:("mx-1 font-weight-medium d-block " + (item.bvnVerified ? 'success--text' : 'error--text'))},[_vm._v("BVN")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }